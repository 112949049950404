
#new-action-modal {
  .modal-body {
    padding-left: 0px;
    padding-right: 0px;
  }
  .actions-section {
    display: flex;
  }
  .list-groups {
    width: 300px;
    .group-item {
      background: #4e5468;
      color: #FFF;
      padding: 16px 12px;
      font-weight: 700;
      border-bottom: 1px solid #353a4a;
      cursor: pointer;
      &:hover {
        background-color: #353a4a;
      }
      .uil {
        float: right;
      }
    }
  }
  .list-actions {
    flex: 1 1 auto;
    padding: 0px 15px;
    .action-item {
      display: flex;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      margin-bottom: 10px;
      .icon {
        padding: 10px;
      }
      .name {
        font-weight: 700;
        cursor: pointer;
        padding-top: 7px;
      }
      p {
        margin-bottom: 0px;
        line-height: 18px;
      }
    }
  }
}
