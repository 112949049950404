<template>
  <div>
    <template v-if="!loadingAutomation">
      <ValidationObserver
          v-slot="{ handleSubmit, invalid, touched }"
          ref="automationForm"
      >
        <form @submit.prevent="handleSubmit(saveAutomation)">
          <div class="row">
            <div class="col-sm-2 mb-4">
              <label>Active</label>
              <div class="pt-2">
                <b-form-checkbox
                    v-model="automation.active"
                    switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-sm-10 text-right">
              <b-button
                  class="mt-4"
                  variant="primary"
                  :disabled="loadingSave || (invalid && touched)"
                  type="submit"
              >
                <b-spinner v-if="loadingSave" small />
                <span v-else><i class="uil uil-upload-alt"></i> Save</span>
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div class="card">
        <div class="card-body">
          <div class="automations-page">
            <div class="automation-editor">
              <template v-if="automation.blocks.length">
                <div class="block-item">
                  <div class="block-content">
                    <div class="action-block">
                      <div class="common-action start-block">
                        <span>Start</span>
                      </div>
                    </div>
                  </div>
                </div>
                <Editor :automation="automation"/>
                <div class="block-item">
                  <div class="block-content">
                    <div class="action-block new-block complete-block" style="padding-bottom: 0px"></div>
                    <div class="action-block">
                      <div class="common-action complete-action end-block">
                        <span>End</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="block-item">
                  <div class="block-content">
                    <b-button class="px-5" @click="setupBlocks">
                      Start
                    </b-button>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="text-center">
      <b-spinner />
    </div>
  </div>
</template>

<script>
import Editor from './Editor'
import Vue from "vue";

export default {
  components: {
    Editor,
  },

  data() {
    return {
      automation: {
        blocks: [],
        active: true,
      },
      loadingSave: false,
      loadingAutomation: false,
      constAutomation: null,
      user:null
    }
  },

  computed: {

  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    if(this.user.business.hasOwnProperty('review_request_series') && this.user.business.review_request_series) {
      this.automation = this.user.business.review_request_series;
    }
    this.constAutomation = JSON.parse(JSON.stringify(this.automation))
  },

  methods: {
    setupBlocks(){
      this.$set(this.automation,'blocks',[{"icon": "uil uil-envelope", "name": "Send SMS", "level": "1", "action": "Notification", "options": {"type": "EmailAndSms", "email": null, "phone": null, "method": "Sms", "source": "Redemption", "content": "Hi [first_name], thanks for using [business_name]. Would you take few seconds and leave us a review - [review_link]", "prequalify": false}, "isNewBlock": false, "description": "Send an sms"}, {"icon": "uil uil-envelope", "name": "Send Email", "level": "1", "action": "SendEmail", "options": {"name": "[business_name]", "images": [], "method": "Sms", "message": "<p>Hi [first_name]</p>\n<p>Thanks for using [business_name]. Would you take a few seconds and leave us a review - [review_link].</p>\n<p>Sincerely,<br />[business_name]</p>", "subject": "Thank you for using [business_name]", "integration_user": null}, "isNewBlock": false, "description": "Send an email"}]);
    },
    saveAutomation() {
      this.loadingSave = true
      let params = {
        'review_request_series': this.automation,
        'business_id': this.user.business.id
      };
      this.$store
          .dispatch('business/updateContactSeries', params)
          .then((resp) => {
            console.log(resp);
            this.loadingSave = false
            this.automation = JSON.parse(JSON.stringify(resp.data.review_request_series));
            this.constAutomation = JSON.parse(JSON.stringify(resp.data.review_request_series));
            this.user.business.review_request_series = this.automation;
          })
          .catch((err) => {
            if (err.response.data.errors) {
              console.log(err.response.data.errors);
            }
            this.loadingSave = false
          })
      /* this.$store.dispatch('automation/createOrUpdate', { automation: this.automation })
          .then((res) => {
            this.loadingSave = false
            this.automation = JSON.parse(JSON.stringify(res.data))
            this.constAutomation = JSON.parse(JSON.stringify(res.data))
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.automationForm.setErrors(err.response.data.errors)
            }
            this.loadingSave = false
          }) */
    },
  },

  beforeRouteLeave (to, from , next) {

    let diff = this.difference(this.automation, this.constAutomation)
    console.log({
      t:this.automation,
      o:this.constAutomation,
      diff:diff
    });
    if (Object.keys(diff).length) {
      const answer = window.confirm('Are you sure you want to leave without saving?')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>
<style>
.automation-editor{
  margin: 50px auto 0;
}
.automations-page .automation-editor .block-item .new-block.complete-block{
  /*&:before {
    left: calc(50% - 1px);
  }*/

}
.automations-page .action-block {
  .start-block{
    background: #28a745;
    color: white;
    opacity: .9;
  }
  .end-block {
    background: #ccc;
  }
}
.complete-action, .start-block{
  cursor: default !important;
}
</style>
